<template>
  <div id="article-page">
    <loading :can-cancel="true"></loading>
    <div class="article-page">
      <div class="breadcrumb-wrapper">
        <Breadcrumb :crumbs="crumbs" @selected="selected" />
      </div>
      <div class="article-card">
        <ArticleCard :article="article" />
      </div>
      <div class="article-send-comment">
        <div class="send-article-textarea-wrapper">
          <label for="send-comment" class="send-article__textarea-label"
            >ارسال پیام</label
          >
          <textarea
            :class="{ error: error.send_comment_textarea }"
            id="send-comment"
            class="send-article__textarea"
            v-model="send_comment_textarea"
            ref="send_comment_textarea"
          ></textarea>
          <div class="error-message">
            {{ error.send_comment_textarea }}
          </div>
        </div>
        <div class="article-send-comment__footer">
          <div class="gray-input">
            <label for="send-comment-name" class="input-label">نام</label>
            <input
              :class="{ error: error.send_comment_name }"
              type="text"
              id="send-comment-name"
              class="send-comment__input"
              v-model="send_comment_name"
              ref="send_comment_name"
            />
            <div class="error-message">
              {{ error.send_comment_name }}
            </div>
          </div>
          <div class="gray-input">
            <label for="send-comment-email" class="input-label"
              >آدرس ایمیل</label
            >
            <input
              :class="{ error: error.send_comment_email }"
              type="email"
              id="send-comment-email"
              class="send-comment__input"
              v-model="send_comment_email"
              ref="send_comment_email"
            />
            <div class="error-message">
              {{ error.send_comment_email }}
            </div>
          </div>
          <div class="send-comment__button-wrapper" @click="submit">
            <button class="send-comment-button">
              ارسال نظر
            </button>
          </div>
        </div>
      </div>
      <div
        class="user-comment-wrapper"
        v-if="article.comments ? article.comments.length : []"
      >
        <div class="user-comment__title">نظرات کاربران</div>
        <div class="user-comment-card-wrapper">
          <div
            class="user-comment-reply__wrapper"
            v-for="comment in article.comments"
            :key="comment.id"
          >
            <div class="user-comment-reply__container">
              <img
                src="../assets/images/user-comment-icon.svg"
                alt=""
                class="user-comment__image"
              />
              <div class="user-comment-card__wrapper">
                <div class="user-comment-card__data">
                  {{ comment.jalali_created_at_date }}
                </div>
                <div class="user-comment-card__content">
                  {{ comment.text }}
                </div>
                <div class="user-comment-card__footer">
                  <img
                    src="../assets/images/user-comment-replay-icon.svg"
                    alt=""
                    :class="[reply_comment ? 'show-reply-form' : '']"
                    class="user-comment-card__icon"
                    @click="showCommentReply"
                  />
                  <div class="like-dislike-wrapper">
                    <div class="dislike-wrapper">
                      <img
                        src="../assets/images/blue-dislike-icon.svg"
                        alt=""
                        class="dislike-icon"
                        :class="[
                          comment.user_disliked_this_comment
                            ? 'dislike-color'
                            : 'dislike-image-color'
                        ]"
                        @click="disLike"
                      />
                      <div
                        class="dislike-count"
                        :class="[dislike_active ? 'dislike-count-color' : '']"
                      >
                        {{ comment.dislike_count }}
                      </div>
                    </div>
                    <div class="like-wrapper">
                      <img
                        src="../assets/images/like-green-icon.svg"
                        alt=""
                        class="dislike-icon"
                        :class="[
                          comment.user_liked_this_comment
                            ? 'like-color'
                            : 'like-image-color'
                        ]"
                        @click="like"
                      />
                      <div
                        class="like-count"
                        :class="[like_active ? 'like-count-color' : '']"
                      >
                        {{ comment.like_count }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="user-comment__reply" v-if="reply_comment">
                  <div class="user-comment-reply__form">
                    <label
                      class="user-comment-reply__label"
                      for="user-comment-reply__textarea"
                      >متن نظر</label
                    >
                    <textarea
                      id="user-comment-reply__textarea"
                      class="user-comment-reply__textarea"
                      v-model="textarea_comment_reply"
                      ref="textarea_comment_reply"
                    ></textarea>
                  </div>
                  <div class="article-send-comment__footer">
                    <div class="gray-input">
                      <label for="send-comment-reply-name" class="input-label"
                        >نام</label
                      >
                      <input
                        type="text"
                        id="send-comment-reply-name"
                        class="send-comment__input"
                        v-model="send_comment_reply_name"
                        ref="send_comment_reply_name"
                      />
                    </div>
                    <div class="gray-input">
                      <label for="send-comment-reply-email" class="input-label"
                        >آدرس ایمیل</label
                      >
                      <input
                        type="email"
                        id="send-comment-reply-email"
                        class="send-comment__input"
                        v-model="send_comment_reply_email"
                        ref="send_comment_reply_email"
                      />
                    </div>
                    <div class="send-comment__button-wrapper">
                      <button class="send-comment-button">ارسال پیام</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="article-page-slider-wrapper">
          <SliderCard :items="articles" />
        </div>
      </div>
    </div>
    <div class="footer-wrapper">
      <Footer />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import ArticleCard from "@/components/ArticleCard";
import SliderCard from "@/components/HomePage/Cards/SliderCard";
import Footer from "@/components/Footer";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: { Footer, SliderCard, ArticleCard, Breadcrumb, Loading },
  data() {
    return {
      crumbs: [
        "خانه",
        "بیت کویین",
        "هاوینگ یا نصف شدن پاداش استخراج بیت کوین‌‌ (Halving) چیست؟"
      ],
      article: {},
      articles: [],
      article_id: this.$route.params.id,
      send_comment_textarea: "",
      send_comment_name: "",
      send_comment_email: "",
      send_comment_reply_name: "",
      send_comment_reply_email: "",
      textarea_comment_reply: "",
      reply_comment: false,
      disLike_count: 2,
      like_count: 6,
      dislike_active: false,
      like_active: false,
      error: {}
    };
  },
  methods: {
    selected(crumb) {
      console.log(crumb);
    },
    showCommentReply() {
      this.reply_comment = !this.reply_comment;
    },
    like() {
      if (!this.like_active) {
        this.like_count++;
        return (this.like_active = true);
      }
      if (this.like_active) {
        this.like_count--;
        return (this.like_active = false);
      }
    },
    disLike() {
      if (this.dislike_active === false) {
        this.disLike_count++;
        return (this.dislike_active = true);
      }
      if (this.dislike_active) {
        this.disLike_count--;
        return (this.dislike_active = false);
      }
    },
    async getArticle(id) {
      let loader = this.$loading.show();
      try {
        let response = await axios(`/blog/${id}`);
        this.article = response.data;
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
      loader.hide();
    },
    async getArticles() {
      try {
        let response = await axios("/blog");
        this.articles = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      this.error = {};
      if (!this.send_comment_textarea) {
        this.error.send_comment_textarea = "متن نظر را بنویسید";
        this.$refs.send_comment_textarea.focus();
        return false;
      }

      if (!this.send_comment_name) {
        this.error.send_comment_name = "نام را وارد کنید";
        this.$refs.send_comment_name.focus();
        return false;
      }

      if (!this.send_comment_email) {
        this.error.send_comment_email = "ایمیل را وارد کنید";
        this.$refs.send_comment_email.focus();
        return false;
      }

      let data = {
        text: this.send_comment_textarea,
        name: this.send_comment_name,
        email: this.send_comment_email
      };

      let loader = this.$loading.show();
      try {
        let response = await axios({
          url: `/post/${this.$route.params.id}/comment`,
          method: "POST",
          data: data
        });
        if (!response.data.status) {
          this.$toast.error(response.data.message);
        }
        this.$toast.success(response.data.message);
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
      this.send_comment_textarea = null;
      this.send_comment_name = null;
      this.send_comment_email = null;
      loader.hide();
    }
    // commetnSubmit() {
    //   if (!)
    // }
  },
  mounted() {
    this.getArticle(this.$route.params.id);
    this.getArticles();
  }
};
</script>

<style scoped>
.article-page {
  padding: 0 10px;
}

.breadcrumb-wrapper {
  margin-top: 40px;
}

.article-card {
  margin-top: 20px;
}

.article-send-comment {
  width: 100%;
  /*max-width: 1300px;*/
  height: auto;
  min-height: 294px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 20px;
  padding: 20px;
}

.send-article__textarea-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.send-article__textarea {
  resize: none;
  width: 100%;
  min-height: 120px;
  border: none;
  outline: none;
  background-color: rgba(31, 60, 136, 0.1);
  border-radius: 10px;
  padding: 10px;
}

.article-send-comment__footer {
  display: flex;
  align-items: center;
  gap: 65px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.send-comment__input {
  width: 100%;
  border: none;
  outline: none;
  background-color: rgba(31, 60, 136, 0.2);
  border-radius: 5px;
  padding: 4px;
}

.gray-input {
  max-width: 280px;
  width: 100%;
}

.input-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #121212;
}

.send-comment__button-wrapper {
  max-width: 150px;
  width: 100%;
  margin-top: auto;
}

.send-comment-button {
  width: 100%;
  background-color: #1f3c88;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  outline: none;
  padding: 4px;
  font-family: "Vazir Medium FD";
  cursor: pointer;
}

.article-send-comment {
  margin-bottom: 20px;
}

.user-comment__image {
  flex: 1;
  align-self: baseline;
  display: none;
}

.user-comment__title {
  font-style: normal;
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  display: flex;
  color: #121212;
  margin-bottom: 20px;
  font-family: "Vazir Medium FD";
}

.user-comment-card-wrapper {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  /*min-height: 624px;*/
  margin-bottom: 65px;
  padding: 20px;
  align-self: flex-end;
  margin-top: 20px;
  /*height: 160px;*/
}

.footer-wrapper {
  margin-top: 120px;
}

.user-comment__wrapper {
  /* display: flex; */
  gap: 15px;
  /* min-height: 200px; */
  height: 160px;
  display: flex;
  flex-wrap: wrap;
}

.user-comment-reply__wrapper {
  /* display: flex; */
  gap: 15px;
  /* min-height: 200px; */
  /*height: 160px;*/
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  flex-direction: column;
}

.user-comment-card__wrapper {
  max-width: 1300px;
  width: 100%;
  background-color: rgba(31, 60, 136, 0.1);
  border-radius: 10px;
  min-height: 122px;
  padding: 10px 20px 12px 20px;
  align-self: flex-end;
  margin-top: 20px;
}

.user-comment-reply__container {
  width: 100%;
  display: flex;
  gap: 20px;
}

.user-comment-card__data {
  font-style: normal;
  font-weight: normal;
  /*font-size: 14px;*/
  font-size: 10px;
  line-height: 22px;
  color: rgba(18, 18, 18, 0.6);
  float: left;
}

.user-comment-card__icon {
  width: 20px;
  cursor: pointer;
  opacity: 0.6;
}

.show-reply-form {
  opacity: unset;
}

.user-comment-card__content {
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #121212;
  margin-top: 40px;
}

.user-comment-card__footer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dislike-count {
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  color: #1f3c88;
}

.dislike-icon {
  width: 20px;
  cursor: pointer;
}

.like-count {
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  color: #039100;
}

.like-dislike-wrapper {
  display: flex;
  gap: 15px;
  align-items: center;
}

.dislike-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

.like-wrapper {
  display: flex;
  gap: 5px;
  align-items: center;
}

.user-comment-reply__form {
  margin-top: 20px;
}

.user-comment-reply__label {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
}

.user-comment-reply__textarea {
  width: 100%;
  height: 75px;
  border: none;
  outline: none;
  background-color: rgba(31, 60, 136, 0.2);
  border-radius: 10px;
  resize: none;
  padding: 10px;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

.dislike-image-color {
  filter: invert(16%) sepia(48%) saturate(3650%) hue-rotate(217deg)
    brightness(89%) contrast(88%);
}

.dislike-color {
  filter: invert(10%) sepia(78%) saturate(7486%) hue-rotate(11deg)
    brightness(96%) contrast(119%);
}

.dislike-count-color {
  color: #dc0000;
}

.like-image-color {
  filter: invert(30%) sepia(100%) saturate(1028%) hue-rotate(87deg)
    brightness(103%) contrast(102%);
}

.like-color {
  filter: invert(14%) sepia(28%) saturate(7328%) hue-rotate(220deg)
    brightness(96%) contrast(85%);
}

.like-count-color {
  color: #1f3c88;
}

@media (min-width: 960px) {
  .article-page {
    padding: 0 70px;
  }

  .user-comment__wrapper {
    flex-wrap: unset;
  }

  .user-comment-reply__wrapper {
    flex-wrap: unset;
  }

  .user-comment__image {
    display: block;
  }

  .user-comment__title {
    font-size: 20px;
  }

  .user-comment-card__data {
    font-size: 14px;
  }

  .dislike-count {
    font-size: 20px;
  }

  .like-count {
    font-size: 20px;
  }

  .user-comment-card__content {
    font-size: 16px;
  }

  .user-comment-card__icon[data-v-445875db] {
    width: auto;
  }
}
</style>
