<template>
  <div class="article-card">
    <loading :can-cancel="true"></loading>
    <div class="article-image-wrapper">
      <img :src="article ? article.image : ''" alt="" class="article-image" />
    </div>
    <div class="comment-like-wrapper">
      <div class="comment-wrapper">
        <div class="comment-count">
          {{ article ? article.comment_count : 0 }}
        </div>
        <img
          src="../assets/images/coment-icon.svg"
          alt=""
          class="comment-image"
        />
      </div>
      <div class="like-wrapper">
        <div class="like-count">{{ article ? article.like_count : 0 }}</div>
        <img
          src="../assets/images/like-article-icon.svg"
          alt=""
          class="like-image"
          @click="likePost"
          :class="{ liked: article ? article.user_liked_this_post : false }"
        />
      </div>
    </div>
    <div class="article-title">
      {{ article ? article.title : "" }}
    </div>
    <div class="article-content">
      {{ article ? article.content : "" }}
    </div>
    <div class="article-card-footer">
      <div class="article-tag-wrapper" v-if="article.tags !== []">
        <div v-for="(tag, ti) in article.tags ? article.tags : []" :key="ti">
          <ArticleTag :tag="tag.name" />
        </div>
        <!--        <ArticleTag title="تاریخچه"/>-->
      </div>
      <div class="article-social-media-icon">
        <img
          src="../assets/images/twitter-article-icon.svg"
          alt=""
          class="twitter-icon"
        />
        <img
          src="../assets/images/instagram-article-icon.svg"
          alt=""
          class="instagram-icon"
        />
        <img
          src="../assets/images/telegram-article-icon.svg"
          alt=""
          class="telegram-icon"
        />
        <img
          src="../assets/images/linkden-article-icon.svg"
          alt=""
          class="linkden-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ArticleTag from "@/components/ArticleTag";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  name: "ArticleCard",
  components: { ArticleTag, Loading },
  props: {},
  data() {
    return {
      likedPost: false,
      article: null
    };
  },
  mounted() {
    this.getArticle(this.$route.params.id);
  },
  methods: {
    async likePost() {
      if (this.article.user_liked_this_post) {
        return false;
      }
      let loader = this.$loading.show();
      try {
        let response = await axios(`/post/${this.article.id}/like`);
        this.article = response.data;
        this.getArticle(this.$route.params.id);
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
      loader.hide();
    },
    async getArticle(id) {
      let loader = this.$loading.show();
      try {
        let response = await axios(`/blog/${id}`);
        this.article = response.data;
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
      loader.hide();
    }
  }
};
</script>

<style scoped>
.article-card {
  width: 100%;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
}

.article-card {
  padding: 20px;
}

.article-image-wrapper {
  width: 100%;
  height: 640px;
  border-radius: 10px;
}

.article-image {
  width: 100%;
  height: 640px;
}

.comment-like-wrapper {
  display: flex;
  gap: 25px;
  margin-top: 20px;
  float: left;
}

.comment-wrapper {
  display: flex;
  gap: 8px;
}

.comment-count {
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  color: #070d59;
}

.like-wrapper {
  display: flex;
  gap: 8px;
}

.like-count {
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  color: #070d59;
}

.article-title {
  font-style: normal;
  font-weight: 500;
  /*font-size: 20px;*/
  font-size: 16px;
  line-height: 31px;
  color: #121212;
  margin-top: 50px;
  margin-bottom: 45px;
  font-family: "Vazir Medium FD";
}

.article-content {
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 12px;
  line-height: 25px;
  color: #121212;
}

.article-tag-wrapper {
  display: flex;
  /*flex-wrap: wrap;*/
  gap: 15px;
  margin-top: 35px;
  margin-bottom: 20px;
  /*float: right;*/
}

.article-social-media-icon {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.article-card-footer {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  flex-wrap: wrap;
}

.like-image {
  opacity: 0.65;
  cursor: pointer;
}

.liked {
  opacity: 1;
}

@media (min-width: 540px) {
  .article-card-footer {
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 960px) {
  .comment-count {
    font-size: 20px;
  }

  .like-count {
    font-size: 20px;
  }

  .article-title {
    font-size: 20px;
  }

  .article-content {
    font-size: 16px;
  }
}
</style>
