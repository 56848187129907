<template>
  <hooper style="height: 350px;" :settings="hooperSettings">
    <slide style="width: 360px" v-for="article in items" :key="article.id">
      <div id="card" @click="goArticle(article.id)">
        <div class="card-container">
          <img class="card-image" :src="article.image" />
          <div class="body">
            <div class="header">
              <div class="header">{{ article.title }}</div>
              <div class="information">
                <img
                  class="icon"
                  src="../../../assets/images/carbon_view-filled.svg"
                  alt=""
                />
                <span class="view">{{ article.view }}</span>
                <div class="date">{{ article.jalali_created_at_date }}</div>
              </div>
            </div>
            <div class="content">
              {{ article.body }}
            </div>
          </div>
        </div>
      </div>
    </slide>
    <hooper-navigation slot="hooper-addons"></hooper-navigation>
  </hooper>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";

import "hooper/dist/hooper.css";

export default {
  name: "SliderCard",
  props: {
    items: {
      required: true
    }
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation
  },
  data() {
    return {
      hooperSettings: {
        rtl: true,
        itemsToShow: 1,
        centerMode: true,
        breakpoints: {
          800: {
            centerMode: false,
            itemsToShow: 2
          },
          1000: {
            centerMode: false,
            itemsToShow: 4
          }
        }
      }
    };
  },
  methods: {
    goArticle(id) {
      this.$router.push(`/article/${id}`);
    }
  }
};
</script>

<style scoped>
#card {
  /* width: 100%; */
}

.card-container {
  width: 343px;
  height: 331px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.card-image {
  width: 343px;
  height: 228px;
}

.body {
  margin-right: 15px;
  margin-left: 15px;
}

.header {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  text-align: right;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.information {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 35%;
}

.content {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  height: 44px;
  width: 290px;
  overflow: hidden;
}

.icon {
  width: 15px;
  height: 15px;
  opacity: 0.9;
}

.view {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  text-align: right;
  color: #070d59;
  opacity: 0.9;
}

.date {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: right;
  color: #070d59;
  opacity: 0.9;
}

/deep/ .icon-arrowRight {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: rgba(7, 13, 89, 0.8);
  fill: #ffffff;
}

/deep/ .icon-arrowLeft {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: rgba(7, 13, 89, 0.8);
  fill: #ffffff;
}
</style>
