<template>
  <nav>
    <ol class="breadcrumb-wrapper">
      <li v-for="(crumb, ci) in crumbs" :key="ci" class="breadcrumb__item">
        <button
          class="button-link"
          :class="{ disabled: isLast(ci) }"
          @click="selected(crumb)"
        >
          {{ crumb }} <span class="arrow" v-if="!isLast(ci)"> > </span>
        </button>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    crumbs: {
      type: Array,
      require: true
    }
  },
  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
    selected(crumb) {
      this.$emit("selected", crumb);
    }
  }
};
</script>
<style scoped>
ol {
  list-style: none;
}
.breadcrumb-wrapper {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.button-link {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
  /*font-size: 16px;*/
  line-height: 25px;
  font-size: 12px;
}

.disabled {
  cursor: none;
  color: rgba(0, 0, 0, 0.4);
}

@media (min-width: 960px) {
  .button-link {
    font-size: 16px;
  }
}
</style>
