<template>
  <div class="article-tag">
    <div class="content">{{ tag }}</div>
  </div>
</template>

<script>
export default {
  name: "ArticleTag",
  props: ["tag"]
};
</script>

<style scoped>
.article-tag {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: rgba(31, 60, 136, 0.1);
  max-width: 105px;
  width: 100%;
  padding: 2px 31px;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(18, 18, 18, 0.7);
}
</style>
